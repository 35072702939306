import { Component, Injector, Input, OnInit, computed, effect, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ErrorMessageMap } from '../../pipes/error-message.pipe';
import { EpiService } from '../../services/epi/epi.service';
import { NumberPickerComponent } from '../number-picker/number-picker.component';

@Component({
	selector: 'cramo-cart-order-row-quantity-picker',
	standalone: true,
	imports: [NumberPickerComponent],
	templateUrl: './cart-order-row-quantity-picker.component.html',
})
export class CartOrderRowQuantityPickerComponent implements OnInit {
	private epiService = inject(EpiService);
	private injector = inject(Injector);

	@Input({ required: true })
	public formControl: FormControl<number>;

	public max = input<number>();
	private currentMaxValidator: ValidatorFn | null = null;

	private appData = toSignal(this.epiService.appData$, { requireSync: true });
	protected errorMessageMap = computed<ErrorMessageMap>(() => {
		const max = this.max();

		return {
			required: this.appData().formElements.fieldIsRequired,
			min: `${this.appData().cart.atLeastOneItemIsRequired}`,
			...(max
				? {
						max: `${this.appData().cart.aboveMaxAvailabilityMessage}`.replace('{{quantity}}', max.toString()),
					}
				: {}),
		};
	});

	ngOnInit(): void {
		this.formControl.addValidators(Validators.min(1));
		this.formControl.addValidators(Validators.required);

		effect(
			() => {
				const max = this.max();
				if (!max) return;
				this.updateMaxValidator(max);
			},
			{ injector: this.injector },
		);
	}

	private updateMaxValidator(maxValue: number): void {
		if (this.currentMaxValidator) {
			this.formControl.removeValidators(this.currentMaxValidator);
		}

		this.currentMaxValidator = Validators.max(maxValue);
		this.formControl.addValidators(this.currentMaxValidator);
		this.formControl.updateValueAndValidity({ emitEvent: false });
	}
}
