import { Component, HostBinding, Input, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { ErrorMessageMap, ErrorMessagePipe } from '../../pipes/error-message.pipe';
import { EpiService } from '../../services/epi/epi.service';
import { MatStepInteractionBase } from '../../utils/mat-step-interaction-base';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'cramo-number-picker',
	standalone: true,
	imports: [MatButtonModule, IconComponent, FormsModule, ReactiveFormsModule, MatError, ErrorMessagePipe],
	templateUrl: './number-picker.component.html',
	styleUrls: ['./number-picker.component.scss'],
})
export class NumberPickerComponent extends MatStepInteractionBase {
	@HostBinding('class.focused') protected isFocused = false;

	private epiService = inject(EpiService);
	protected appData = toSignal(this.epiService.appData$, { requireSync: true });

	protected formControl: FormControl<number>;
	@Input({ required: true }) public set control(value: AbstractControl<number>) {
		if (value instanceof FormControl) {
			this.formControl = value;
		} else {
			throw new Error('The control must be an instance of FormControl');
		}
	}

	public errorMessageMap = input<ErrorMessageMap>({});

	protected increase(): void {
		this.formControl.markAsTouched();
		this.formControl.setValue(this.formControl.value + 1);
	}

	protected decrease(): void {
		this.formControl.markAsTouched();
		this.formControl.setValue(this.formControl.value - 1);
	}

	protected preventForbiddenCharacters(event: KeyboardEvent): void {
		const forbiddenKeys = ['.', ',', '-', 'e', ' ', '+'];

		if (forbiddenKeys.includes(event.key)) {
			event.preventDefault();
		}
	}

	protected onFocus() {
		this.isFocused = true;
	}

	protected onBlur() {
		this.isFocused = false;
	}
}
