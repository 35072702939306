import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCardAppearance, MatCardModule } from '@angular/material/card';

@Component({
	selector: 'cramo-card',
	standalone: true,
	imports: [MatCardModule, CommonModule],
	templateUrl: './card.component.html',
	styleUrl: './card.component.scss',
})
export class CardComponent {
	public appearance = input<MatCardAppearance>('raised');
	public hideShadow = input(false);
	public removePadding = input(false);
	public overflowHidden = input(false);
	public hasError = input(false);
}
