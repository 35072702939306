import { CommonModule } from '@angular/common';
import { Component, Signal, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs';
import { FadeInImageOnLoadDirective } from '../../directives/fade-in-on-load.directive';
import { EpiService } from '../../services/epi/epi.service';
import { AppData } from '../../types/translations.types';
import { ButtonComponent } from '../button/button.component';
import { CardComponent } from '../card/card.component';
import { CartOrderRowQuantityPickerComponent } from '../cart-order-row-quantity-picker/cart-order-row-quantity-picker.component';
import { AddToCartSuccessDialogService } from './services/add-to-cart-success-dialog.service';
import { CartOrderRow } from './types/add-to-cart-success-dialog.types';

export type AddToCartSuccessDialogData = { cartOrderRowId: string };
@Component({
	standalone: true,
	imports: [
		CommonModule,
		ButtonComponent,
		FadeInImageOnLoadDirective,
		RouterModule,
		CardComponent,
		MatDialogModule,
		CartOrderRowQuantityPickerComponent,
	],
	providers: [AddToCartSuccessDialogService],
	templateUrl: './add-to-cart-success-dialog.component.html',
	styleUrl: './add-to-cart-success-dialog.component.scss',
})
export class AddToCartSuccessDialogComponent {
	private addToCartSuccessDialogService = inject(AddToCartSuccessDialogService);
	private dialogRef = inject(MatDialogRef);
	private data: AddToCartSuccessDialogData = inject(MAT_DIALOG_DATA);
	private epiService = inject(EpiService);

	protected orderRow: Signal<CartOrderRow | undefined>;
	protected appData: Signal<AppData>;
	protected formControl = new FormControl<number>(1, { updateOn: 'blur', nonNullable: true });
	protected isLoadingQuantity = signal(false);

	constructor() {
		this.appData = toSignal(this.epiService.appData$, { requireSync: true });
		this.dialogRef.disableClose = true;

		const orderRow$ = this.addToCartSuccessDialogService.getCartOrderRowById(this.data.cartOrderRowId).pipe(
			take(1),
			tap((orderRow) => {
				this.formControl.setValue(orderRow.quantity, { emitEvent: false });
			}),
		);

		this.orderRow = toSignal(orderRow$);

		this.setupQuantityChangeDetection();
	}

	private setupQuantityChangeDetection() {
		this.formControl.valueChanges
			.pipe(
				takeUntilDestroyed(),
				filter(() => this.formControl.valid),
				debounceTime(500),
				tap(() => this.isLoadingQuantity.set(true)),
				switchMap((quantity) =>
					this.addToCartSuccessDialogService.updateCartOrderRowQuantity({
						cartOrderRowId: this.data.cartOrderRowId,
						quantity: quantity, // Valid formControl ensures quantity is not null
					}),
				),
				tap(() => this.isLoadingQuantity.set(false)),
			)
			.subscribe();
	}

	protected close() {
		this.dialogRef.close();
	}
}
