<mat-card
	[appearance]="appearance()"
	[ngStyle]="{}"
	[style.overflow]="overflowHidden() ? 'hidden' : 'auto'"
	[ngClass]="{ 'hide-shadow': hideShadow(), 'remove-padding': removePadding(), 'has-error': hasError() }"
>
	<mat-card-content>
		<ng-content />
	</mat-card-content>
</mat-card>
