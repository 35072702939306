import { inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatStep } from '@angular/material/stepper';

/**
 * Base class to handle MatStep interaction state.
 *
 * This class injects `MatStep` and manages an interaction state signal.
 * It listens to the `interactedStream` of `MatStep` and updates the `isParentStepInteracted` signal accordingly.
 *
 * Components extending this base class need to be descendants of a `mat-step` component to properly inject `MatStep`.
 *
 * @example
 * ```typescript
 * import { Component } from '@angular/core';
 * import { MatStepInteractionBase } from './mat-step-interaction-base';
 *
 * @Component({
 *   selector: 'app-my-component',
 *   template: '<div [class.parent-interacted]="isParentStepInteracted()">Content</div>',
 * })
 * export class MyComponent extends MatStepInteractionBase { }
 * ```
 */
export class MatStepInteractionBase {
	private matStep = inject(MatStep, { optional: true });

	protected isParentStepInteracted = signal(false);

	constructor() {
		if (this.matStep) {
			this.isParentStepInteracted.set(this.matStep.interacted);
			this.matStep.interactedStream.pipe(takeUntilDestroyed()).subscribe((step) => {
				this.isParentStepInteracted.set(step.interacted);
			});
		}
	}
}
