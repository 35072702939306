<div class="number-picker" [class.has-error]="formControl.errors && (formControl.touched || isParentStepInteracted())">
	<div class="number-picker-group">
		<button mat-flat-button class="increase" (click)="decrease()" [disabled]="formControl.disabled">
			<cramo-icon iconName="remove" />
		</button>

		<input
			type="number"
			inputmode="numeric"
			[formControl]="formControl"
			(keypress)="preventForbiddenCharacters($event)"
			[class]="{ invalid: !formControl.valid && !formControl.disabled }"
			(focus)="onFocus()"
			(blur)="onBlur()"
		/>

		<button mat-flat-button class="decrease" (click)="increase()" [disabled]="formControl.disabled">
			<cramo-icon iconName="add" />
		</button>
	</div>

	@if (formControl.errors && (formControl.touched || isParentStepInteracted())) {
		<mat-error>
			{{ formControl.errors | errorMessage: errorMessageMap() }}
		</mat-error>
	}
</div>
